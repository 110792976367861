import { NextPage } from "next";
import { useRouter } from "next/router"; // Import useRouter for dynamic URLs
import Hero from "@/components/home-page-sections/Hero";
import Categories from "@/components/home-page-sections/Categories";
import Stats from "@/components/home-page-sections/Stats";
import Process from "@/components/home-page-sections/Process";
import Testimonials from "@/components/home-page-sections/Testomonials";
import FAQ from "@/components/home-page-sections/FAQ";
import Blog from "@/components/home-page-sections/Blog";
import CTA from "@/components/home-page-sections/CTA";
import Space from "@/components/ui/Space";
import { NextSeo, OrganizationJsonLd } from "next-seo";

export async function getServerSideProps({ locale }) {
  return {
    props: {
      locale,
      title:
        locale === "fa"
          ? "کلاس خصوصی آنلاین با معلم‌های حرفه‌ای | درسون"
          : "Online Tutoring Class With Best Tutors | Darsoon",
      description:
        locale === "fa"
          ? "کلاس خصوصی آنلاین از موسیقی و ریاضی گرفته تا زبان فرانسوی. ویژه فارسی زبانان کانادا، آمریکا، استرالیا و اروپا. با بیش از ۲۰۰ معلم حرفه ای."
          : "Online private tutoring from Music and Math to French. Specifically for Farsi-speaking individuals in Canada, the US, Australia, and Europe. With over 200 professional tutors.",
    },
  };
}

type Props = {
  title: string;
  description: string;
  locale: string;
};

const Page: NextPage = ({ title, description, locale }: Props) => {
  const router = useRouter();
  const baseUrl = "https://darsoon.com";
  const canonicalUrl = `${baseUrl}${router.asPath}`;

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        canonical={canonicalUrl}
        openGraph={{
          title: title,
          description: description,
          url: canonicalUrl,
          locale: locale === "fa" ? "fa_IR" : "en_US",
          site_name: locale === "fa" ? "درسون" : "Darsoon",
          type: "website",
        }}
        additionalLinkTags={[
          {
            rel: "alternate",
            href: `${baseUrl}${router.asPath}`,
            hrefLang: "fa",
          },
          {
            rel: "alternate",
            href: `${baseUrl}/en${router.asPath}`,
            hrefLang: "en",
          },
          {
            rel: "alternate",
            href: `${baseUrl}${router.asPath}`,
            hrefLang: "x-default",
          },
        ]}
      />
      <OrganizationJsonLd
        type="LocalBusiness"
        name="Darsoon"
        url="https://www.darsoon.com/"
        contactPoint={[
          {
            telephone: "+1 (877) 370 3277",
            contactType: "Customer Service",
            availableLanguage: ["Persian", "English"],
          },
        ]}
        address={{
          streetAddress: "47 Brunel Street",
          addressLocality: "Vaughan",
          addressRegion: "ON",
          postalCode: "L6A 0R3",
          addressCountry: "CA",
        }}
      />

      <div>
        <div className="p-4">
          <Hero />
          <Stats />
          <Space size="h-20" />
          <Categories />
          <Space size="h-20" />
          <Process />
          <Space size="h-20" />
        </div>
        <Testimonials />
        <div className="p-4">
          <Space size="h-30" />
          <FAQ />
          <Space size="h-20" />
          <Blog />
          <Space size="h-30" />
          <CTA />
        </div>
      </div>
    </>
  );
};

export default Page;
